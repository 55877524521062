import L from 'leaflet';
import easyButton from 'leaflet-easybutton';

export const map = () => {
  const mapView = document.getElementById('map');

  if (mapView == null) {
    return;
  }

  const lat = '43.745375599695706';
  const lng = '142.37706694781215';

  const map = L.map('map', {
    scrollWheelZoom: false,
  }).setView([lat, lng], 16);

  const myIcon = L.icon({
    iconUrl: '/cms/wp-content/themes/hal-corp/assets/images/marker.png',
    iconSize: [64, 75],
    iconAnchor: [32, 75],
  });

  // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
  //   attribution:
  //     '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  // }).addTo(map);

  L.tileLayer('https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}', {
    attribution: `<a href='https://developers.google.com/maps/documentation' target='_blank'>Google Map</a> | <a href='http://maps.google.co.jp/maps?q=${lat},${lng}' target='_blank'>拡大地図を表示</a>`,
  }).addTo(map);

  L.marker([lat, lng], { icon: myIcon }).addTo(map);

  L.easyButton('fas fa-external-link-alt', function() {
    open(`http://maps.google.co.jp/maps?q=${lat},${lng}`, "_blank" ) ;
  }).addTo(map);
};
