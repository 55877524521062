export const widthHome = () => {
  window.addEventListener('DOMContentLoaded', onLoadFunction);

  function onLoadFunction() {
    onResizeFunction();
    window.addEventListener('resize', onResizeFunction);
  }

  function onResizeFunction() {
    const information = document.querySelector('.s-home__information');

    if (information == null) {
      return;
    }

    const informationWidth = information.clientWidth;
    const informationContent = document.querySelector(
      '.s-home__information-content',
    );
    const informationContentWidth = informationContent.clientWidth;
    const padding = (informationWidth - informationContentWidth) / 2;
    const aboutContent = document.querySelector('.s-home__about-content');
    const solutionContent = document.querySelector('.s-home__solution-content');
    const accessContent = document.querySelector('.s-home__access-content');
    const accessAddress = document.querySelector('.s-home__access-address');

    window.requestAnimationFrame(() => {
      aboutContent.style.paddingLeft = `${padding}px`;
      solutionContent.style.paddingRight = `${padding}px`;
      accessContent.style.paddingLeft = `${padding}px`;
      accessAddress.style.paddingLeft = `${padding}px`;
    });
  }
};
